<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <product titleName="产品" tempType="01"></product>
  </div>
</template>
<script>
  import product from '@/views/common/product.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  export default {
    name: 'productList',
    components: {
      product,
      breadcrumb
    },
    data() {
      return {
        breadStaticData:['主体信息', '产品信息'],
        linkData:[],
        thirdStaticData:[],
        contHeight: ''
      }
    },
    created() {
    },
    mounted(){
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
</style>